.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  color: white;
}

.wrapper > * {
  cursor: pointer;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 250px;
  flex: 1;
  align-items: center;
  position: relative;
}

.openingswrapper {
  width: 100%;
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.openings {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  padding: 8px 8px;
  border-radius: 15px 15px 0px 0px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1;
}
